import React from "react";

export default function Form({ formdata }) {
  return (
    <>
      <section id="form-id" className="form-container">
        <form
          className="form"
          action="https://formsubmit.co/422905d5490147bb6039e642896e025e"
          method="POST"
        >
          <div className="left">
            <div className="top-content">
              <h1>{formdata.heading1}</h1>
              <p>{formdata.para1}</p>
            </div>
            <div className="bottom-content">
              <ul>
                <li>
                  <i className="fa-brands fa-whatsapp"></i>
                  +27 61 897 3346
                </li>
                <li>
                  <i className="fa fa-phone-alt"></i>+27 61 897 3346
                </li>
                <li>
                  <i className="fa fa-paper-plane"></i>
                  info@varsityiq.co.za
                </li>
              </ul>
            </div>
            <div className="last-content">
              <h1>{formdata.heading2}</h1>
              <p>{formdata.para2}</p>
            </div>
          </div>
          <div className="right">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              required
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              required
            />

            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              required
            />

            <input
              type="hidden"
              name="_subject"
              value={formdata.subject}
            ></input>

            <input
              type="hidden"
              name="_autoresponse"
              value={formdata.ares}
            ></input>

            <input type="hidden" name="_template" value="table"></input>

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              required
            ></textarea>

            <input type="submit" value="Submit" className="outline-btn" />
          </div>
        </form>
      </section>
    </>
  );
}
