import React, { useState } from "react";
import ReactQuill from "react-quill";
import Back from "../components/common/back/back";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation } from "react-router";
import moment from "moment";

export default function Write() {
  const state = useLocation().state;

  const [value, setValue] = useState(state?.desc || "");
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const [alt, setAlt] = useState(state?.alt || "");

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const res = await axios.post(
        "https://varsityiq-api.vercel.app/upload",
        formData
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const imgUrl = await upload();

    try {
      state
        ? await axios.put(
            `https://varsityiq-api.vercel.app/posts/${state.id}`,
            {
              title,
              desc: value,
              alt,
              cat,
              img: file ? imgUrl : "",
            }
          )
        : await axios.post(`https://varsityiq-api.vercel.app/posts/`, {
            title,
            desc: value,
            alt,
            cat,
            img: file ? imgUrl : "",
            date: moment(Date.now()).format("YYYY-MM-DD"),
          });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Back title="write blog" />
      <div className="add">
        <div className="content">
          <input
            type="text"
            value={title}
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="editorContainer">
            <ReactQuill
              className="editor"
              theme="snow"
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
        <div className="menu">
          <div className="item">
            <h1>Publish</h1>
            <span>
              <b>Status: </b> Draft
            </span>
            <span>
              <b>Visibility: </b> Public
            </span>
            <input
              style={{ display: "none" }}
              type="file"
              name=""
              id="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label className="file" htmlFor="file">
              Upload Image
            </label>
            <input
              type="text"
              value={alt}
              placeholder="image alt text"
              onChange={(e) => setAlt(e.target.value)}
            />
            <div className="buttons">
              <button>Save as a draft</button>
              <button onClick={handleClick}>Publish</button>
            </div>
          </div>
          <div className="item">
            <h1>Category</h1>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "applications"}
                name="cat"
                value="applications"
                id="applications"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="applications">Applications</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "bursary"}
                name="cat"
                value="bursary"
                id="bursary"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="applications">Bursary</label>
            </div>
            <div className="cat">
              <input
                type="radio"
                checked={cat === "ai"}
                name="cat"
                value="ai"
                id="ai"
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor="applications">AI</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
