import React from "react";
import Back from "../components/common/back/back";

export default function PrivacyPolicy() {
  return (
    <>
      <Back title="Privacy Policy" />
      <div className="legal-container">
        <h1>Privacy Policy</h1>
        <p>
          We take your privacy seriously and are committed to protecting your
          personal information. This Privacy Policy explains how we collect,
          use, and share your personal information when you use our website (the
          "Site") and the services provided by our company ("we" or "us").
        </p>

        <h2>1 Information We Collect</h2>
        <p>
          We collect personal information that you provide to us when you use
          the Site or our services. This may include:
        </p>
        <ul>
          <li>
            Your name, email address, phone number, and other contact
            information.
          </li>
          <li>Your academic history and qualifications.</li>
          <li>
            Your financial information, such as your bank account details or
            credit card information.
          </li>
          <li>Information about your use of the Site and our services.</li>
        </ul>

        <h2>2 How We Use Your Information</h2>
        <p>
          We use your personal information to provide our services to you,
          including:
        </p>
        <ul>
          <li>
            Assisting you with applications to colleges, universities, and other
            tertiary institutions.
          </li>
          <li>
            Assisting you with applications for student accommodation and
            bursaries.
          </li>
          <li>Providing textbook rental services.</li>
          <li>Responding to your inquiries and requests.</li>
          <li>Sending you promotional and marketing communications.</li>
        </ul>
        <p>
          We may also use your personal information for other purposes that are
          consistent with the reason for which we collected it.
        </p>

        <h2>3 SHARING YOUR INFORMATION</h2>
        <p>
          We may share your personal information with third parties for the
          following purposes:
        </p>

        <ul>
          <li>To provide our services to you.</li>
          <li>To comply with legal obligations.</li>
          <li>To protect our rights and property.</li>
          <li>
            To investigate and prevent fraud and other illegal activities.
          </li>
          <li>We do not sell your personal information to third parties.</li>
        </ul>

        <h2>4 YOUR RIGHTS</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information that we have collected. You may also withdraw your consent
          for us to use your personal information. To exercise your rights,
          please contact us using the contact information provided below.
        </p>

        <h2>5 SECURITY OF YOUR INFORMATION</h2>
        <p>
          We take appropriate measures to protect your personal information from
          unauthorized access, use, and disclosure. However, we cannot guarantee
          the security of your personal information.
        </p>

        <h2>6 CHANGES TO THIS POLICY</h2>
        <p>
          We may update this Privacy Policy from time to time. If we make
          material changes to this Privacy Policy, we will notify you by email
          or by posting a notice on the Site.
        </p>

        <h1>COOKIE POLICY</h1>

        <p>
          This Cookie Policy explains how we use cookies and similar
          technologies when you use our website (the "Site").
        </p>

        <h2>1 WHAT ARE COOKIES?</h2>
        <p>
          Cookies are small text files that are stored on your device when you
          use the Site. Cookies help us to improve your experience on the Site
          by remembering your preferences and settings.
        </p>

        <h2>2 TYPES OF COOKIES</h2>
        <p>We use the following types of cookies:</p>

        <ul>
          <li>
            Essential cookies: These cookies are necessary for the Site to
            function properly. They enable you to navigate the Site and use its
            features.
          </li>
          <li>
            Analytical cookies: These cookies help us to understand how you use
            the Site, so that we can improve its performance and functionality.
          </li>
          <li>
            Marketing cookies: These cookies are used to track your browsing
            behavior and to show you personalized ads and content.
          </li>
        </ul>

        <h2>3 HOW TO CONTROL COOKIES</h2>
        <p>
          You can control cookies through your browser settings. Most browsers
          allow you to block or delete cookies. However, if you block or delete
          cookies, some features of the Site may not function properly.
        </p>

        <h2>4 CHANGES TO THIS POLICY</h2>
        <p>
          We may update this Cookie Policy from time to time. If we make
          material changes to this Cookie Policy, we will notify you by email or
          by posting a notice on the Site.
        </p>

        <h2>5 CONTACT US</h2>
        <p>
          If you have any questions or concerns about this Cookie Policy, please
          contact us at +27 61 897 3346 or info@varsityiq.co.za.
        </p>
      </div>
    </>
  );
}
