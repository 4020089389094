const categoryColors = {
  "Tech Culture": "rgb(255,59,48)",
  "Tech News": "rgb(0,113,164)",
  bursary: "rgb(255,45,85)",
  applications: "rgb(52,199,89)",
  ai: "rgb(64,156,255)",
  textbooks: "rgb(255, 179, 64)",
  Cloud: "rgb(175,82,250)",
};

export { categoryColors };
