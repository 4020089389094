import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import PostGrid from "./postgrid";

// const trendingConfig = {
//   1: {
//     gridArea: "1 / 2 / 3 / 3",
//   },
// };

// const featuredConfig = {
//   0: {
//     gridArea: "1 / 1 / 2/ 3",
//     height: "300px",
//   },
//   1: {
//     height: "300px",
//   },
//   3: {
//     height: "630px",
//     marginLeft: "30px",
//     width: "630px",
//   },
// };

// const mergeStyles = function (posts, config) {
//   posts.forEach((post, index) => {
//     post.style = config[index];
//     post.author = "Orlin Thommas";
//     post.description =
//       "ghkhg fhgdtf fkyft fiytdsturd yooui ytf d rdyt uytiytersutr sresro iyiuyeuut riytutrd";
//   });
// };

// const recentPosts = [...trending, ...featured, ...featured];

// mergeStyles(trending, trendingConfig);
// mergeStyles(featured, featuredConfig);

// const lastFeatured = featured.pop();

export default function Blogging() {
  const [posts, setPosts] = useState([]);

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://varsityiq-api.vercel.app/posts${cat}`
        );
        // const res = await axios.get("/posts");
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [cat]);

  return (
    <div className="homeb">
      {/* <Back title="Varsity Blog" /> */}
      {/* <section className="containerb homeb"> */}

      {/* THIS SECTION IS FOR THE FEATURED POSTS */}
      {/* <section className="containerb">
        <div className="rowb"> */}
      {/* <h1>Featured Posts</h1> */}
      {/* <section className="featured-posts-container">
            <PostMasonry posts={featured} columns={2} tagsOnTop={true} />
            <MasonryPost post={lastFeatured} tagsOnTop={true} />
          </section> */}
      {/* <h1>Trending Posts</h1> */}
      {/* <PostMasonry posts={trending} columns={3} /> */}
      {/* </div>
      </section> */}

      {/* THIS SECTION IS FOR THE POST GRID */}
      <div className="bg-white">
        <div className="containerb">
          <div className="rowb">
            <h1>Recent Posts</h1>
            <PostGrid posts={posts} />
          </div>
        </div>
      </div>

      {/* THIS SECTION IS FOR THE TRENDING POSTS  */}
      {/* <section className="containerb">
        <div className="rowb">
          <PostMasonry posts={trending} columns={3} />
        </div>
      </section> */}
    </div>
  );
}
