import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="newsletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>
              varsityIQ newsletter - Stay tune and get the latest from varsityIQ
            </h1>
            <span>a world of education on your fingertips</span>
          </div>
          <form
            className="right row"
            action="https://formsubmit.co/422905d5490147bb6039e642896e025e"
            method="POST"
          >
            <input type="hidden" name="_subject" value="Newsletter"></input>
            <input
              type="hidden"
              name="_autoresponse"
              value="Hello there!

                Thanks for subscribing to our newsletter! Our robots are currently doing a happy dance to celebrate your arrival. Unfortunately, we haven't taught them how to write emails yet, so you'll have to put up with us humans for now.
                
                But don't worry, we promise to bring you the latest and greatest updates on everything concerning education in South Africa, along with some quirky humor to brighten up your day. And if we fail to deliver, feel free to send us a strongly worded email (or a friendly one, we don't discriminate).
                
                Thanks again for joining our newsletter. We promise to make it worth your inbox space.
                
                Cheers,
                varsityIQ."
            ></input>
            <input
              type="email"
              placeholder="Email address"
              name="email"
              required
            />
            <button type="submit">
              {" "}
              <i className="fa fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </section>
      <footer>
        <div className="container padding">
          <div className="box logo">
            <h1>varsityIQ</h1>
            <span>a world of education on your fingertips</span>
            <p>
              From applications to tertiary institutions, to renting and buying
              textbooks, and even AI, our aim is to declutter the choas in the
              South African education system. Education opens doors for success,
              let us be your keys.
            </p>
            {/* <i className="fab fa-facebook-f icon"></i>
            <i className="fab fa-instagram icon"></i>
            <i className="fab fa-twitter icon"></i> */}
            <a
              href="https://www.youtube.com/channel/UC8shqTluxf2AFNJ_-ZeYU_g"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube icon"></i>
            </a>
            {/* <i className="fab fa-linkedin icon"></i> */}
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <Link to="/textbooks" onClick={() => window.scrollTo(0, 0)}>
                <li>Textbook</li>
              </Link>
              <Link to="/varsityIQAi" onClick={() => window.scrollTo(0, 0)}>
                <li>varsityIQAi</li>
              </Link>
              <Link to="/blog" onClick={() => window.scrollTo(0, 0)}>
                <li>Blog</li>
              </Link>
            </ul>
          </div>
          <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                <li>Contact Us</li>
              </Link>
              <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
                <li>Privacy Policy</li>
              </Link>
              <Link
                to="terms-and-conditions"
                onClick={() => window.scrollTo(0, 0)}
              >
                <li>Terms and Conditions</li>
              </Link>
            </ul>
          </div>
          <div className="box last">
            <h3>Have a Question?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                71 Gillepsie Street, Durban, 4001
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>+27 61 897 3346
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                info@varsityiq.co.za
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright @2023 varsityIQ, All rights reserved. | Made by Orlicarpio
        </p>
      </div>
    </>
  );
}
