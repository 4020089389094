import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Menu({ cat, maxPosts }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/posts/?cat=${cat}`);
        setPosts(res.data.slice(1, maxPosts));
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [cat, maxPosts]);

  return (
    <>
      <div className="menu">
        <h1>Other posts you may like</h1>
        {posts.map((post, index) => (
          <div className="post" key={index}>
            <Link to={`/post/${post.id}`}>
              {post && post.img && (
                <img
                  src={require(`../../../public/uploads/${post.img}`)}
                  alt={post.alt}
                />
              )}
            </Link>
            <h2>{post.title}</h2>
          </div>
        ))}
      </div>
    </>
  );
}
