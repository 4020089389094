import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Back from "../components/common/back/back";
import Menu from "../components/common/menu";
import Edit from "../../src/assets/images/edit.png";
import Delete from "../../src/assets/images/delete.png";
import moment from "moment";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import DOMPurify from "dompurify";

export default function Single() {
  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://varsityiq-api.vercel.app/posts/${postId}`
        );
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://varsityiq-api.vercel.app/posts/${postId}`);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Back title="read blog" />
      <div className="single">
        <div className="content">
          {post && post.img && (
            <img
              src={require(`../../public/uploads/${post.img}`)}
              alt={post.alt}
            />
          )}
          <div className="user">
            {post.userImg && <img src={post.userImg} alt="" />}
            <div className="info">
              <span>{post.username}</span>
              {/* {post.username && <span>{post.username}</span>} */}
              <p>Posted {moment(post.date).fromNow()}</p>
            </div>
            {/* {currentUser.username === post.username && ( */}
            {currentUser && currentUser.username === post.username && (
              <div className="edit">
                <Link to={"/write?edit=2"} state={post}>
                  <img src={Edit} alt="" />
                </Link>
                <img onClick={handleDelete} src={Delete} alt="" />
              </div>
            )}
          </div>
          <h1>{post.title}</h1>
          <p
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.desc) }}
          ></p>
        </div>
        <Menu cat={post.cat} maxPosts={4} />
      </div>
    </>
  );
}
