// import React, { useContext, useState } from "react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { AuthContext } from "../../../context/authContext";
import Head from "./head";

export default function Header() {
  const [click, setClick] = useState(false);
  // const { currentUser, logout } = useContext(AuthContext);
  return (
    <>
      <Head />
      <header>
        <nav className="flexSB">
          <ul
            className={click ? "mobile-nav" : "flexSB"}
            onClick={() => setClick(false)}
          >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/textbooks">Textbook</Link>
            </li>
            {/* <li>
              <Link to="/about">About</Link>
            </li> */}
            <li>
              <Link to="/varsityIQAi">varsityIQAi</Link>
            </li>
            {/* <li>
              <Link to="/pricing">Pricing</Link>
            </li> */}
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
            {/* <li>
              <Link to="/login">Login</Link>
            </li> */}
            {/* {currentUser ? (
              <span onClick={logout}>Logout</span>
            ) : (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
            <span>{currentUser?.username}</span> */}
            {/* <li>
              <Link to="/post">Post Viewer</Link>
            </li> */}
          </ul>
          <div className="start">
            <div className="button">varsityIQ</div>
          </div>
          <button className="toggle" onClick={() => setClick(!click)}>
            {click ? (
              <i className="fa fa-times "></i>
            ) : (
              <i className="fa fa-bars"></i>
            )}
          </button>
        </nav>
      </header>
    </>
  );
}
