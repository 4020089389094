import React from "react";
// import Navigation from "./components/navigation";
import Header from "./components/common/heading/header";

// import PageRenderer from "./page-renderer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Blog from "./pages/blog";
import Contact from "./pages/contact-us";
import Footer from "./components/common/footer/footer";
import Textbooks from "./pages/textbooks";
import VarsityIQAi from "./pages/varsityiqai";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions";
import Single from "./pages/single";
import Write from "./pages/write";
import Login from "./pages/login";
import Register from "./pages/register";
import CookieConsent from "react-cookie-consent";

// the code below is from the education tutorial
function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/contact-us" exact element={<Contact />} />
          <Route path="/textbooks" exact element={<Textbooks />} />
          <Route path="/varsityIQAi" exact element={<VarsityIQAi />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/post/:id" exact element={<Single />} />
          <Route path="/write" exact element={<Write />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route
            path="/terms-and-conditions"
            exact
            element={<TermsAndConditions />}
          />
        </Routes>
        <Footer />
        <CookieConsent
          debug={true}
          location="bottom"
          buttonText="Let's Bake!!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          "We use cookies to bake a better website experience. By continuing to
          browse, you're helping us whip up something delicious. But don't
          worry, we won't share the recipe with anyone else."
        </CookieConsent>
      </Router>
    </>
  );
}

export default App;
