import React from "react";
import ReactPlayer from "react-player";

export default function AboutCard({ url, data }) {
  return (
    <>
      <section className="aboutHome">
        <div className="container flexSB">
          <div className="left row">
            <ReactPlayer
              className="react-player"
              url={url}
              width="100%"
              height="50vh"
              controls={true}
            />
          </div>
          <div className="right row">
            <div className="items">
              {data.map((val, index) => {
                return (
                  <div className="item flexSB" key={index}>
                    <div className="text">
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
