import React from "react";

export default function Head() {
  return (
    <div>
      <section className="head">
        <div className="container flexSB">
          <div className="logo">
            <h1>varsityIQ</h1>
            <span>A WORLD OF EDUCATION</span>
            <span>AT YOUR FINGERTIPS</span>
          </div>

          <div className="social">
            {/* <i className="fab fa-facebook-f icon"></i> */}
            {/* <i className="fab fa-instagram icon"></i> */}
            {/* <i className="fab fa-twitter icon"></i> */}
            <a
              href="https://www.youtube.com/channel/UC8shqTluxf2AFNJ_-ZeYU_g"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube icon"></i>
            </a>
            {/* <i className="fab fa-linkedin icon"></i> */}
          </div>
        </div>
      </section>
    </div>
  );
}
