import React from "react";
import { Link } from "react-router-dom";
import Title from "../../common/title/title";

export default function Hero() {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Title
              subtitle="WELCOME TO VARSITYIQ"
              title="University Made Easy"
            />
            <p>
              We not only teach you how to apply for university in South Africa,
              we also do it for you.
            </p>
            <div className="button">
              <a href="#form-id">
                <button className="primary-btn">
                  APPLY WITH US<i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </a>
              <Link to="/textbooks">
                <button>
                  TEXTBOOKS <i className="fa fa-long-arrow-alt-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
}
