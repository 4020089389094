import React from "react";
import Back from "../components/common/back/back";

export default function Contact() {
  const map =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3460.219210556676!2d31.036100714729187!3d-29.857951329587024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef7a84a30d9611f%3A0xe46eaf614754b758!2s71%20Gillespie%20St%2C%20South%20Beach%2C%20Durban%2C%204001!5e0!3m2!1sen!2sza!4v1677751736403!5m2!1sen!2sza" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"';

  return (
    <>
      <Back title="Contact Us" />
      <section className="contact padding">
        <div className="container shadow flexSB">
          <div className="left row">
            <iframe src={map} title="office address"></iframe>
          </div>
          <div className="right row">
            <h1>Contact Us</h1>
            <p>
              Let's us know whether you need us to apply to varsity for you or
              if you need a particular textbook, or anything else really!
            </p>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS: </h4>
                <p>71 Gillepsie Street, Durban, 4001</p>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p>info@varsityiq.co.za</p>
              </div>
              <div className="box">
                <h4>PHONE:</h4>
                <p>+27 61 897 3346</p>
              </div>
            </div>

            <form
              action="https://formsubmit.co/422905d5490147bb6039e642896e025e"
              method="POST"
            >
              <input
                type="hidden"
                name="_subject"
                value="varsityIQ Enquiry Submission"
              ></input>
              <input
                type="hidden"
                name="_autoresponse"
                value="Ah, an enquiry form. The digital equivalent of knocking on my virtual door. Come on in, take a seat, and let's get to the bottom of whatever conundrum has brought you here. Whether it's a burning question, a deep existential crisis, or just some good old-fashioned procrastination, I'm here to assist you with equal parts wit and wisdom."
              ></input>
              <input type="hidden" name="_template" value="table"></input>
              <div className="flexSB">
                <input type="text" placeholder="Name" name="name" required />
                <input type="email" placeholder="Email" name="email" required />
              </div>
              <input type="text" placeholder="Subject" name="subject" />
              <textarea
                cols="30"
                rows="10"
                placeholder="Create a message here..."
                name="message"
              ></textarea>
              <button className="primary-btn" type="submit">
                SEND MESSAGE
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
