import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import TagRow from "./tagrow";
import moment from "moment";

export default function PostGrid({ posts }) {
  const [pageSize, setPageSize] = useState(9);
  const [current, setCurrent] = useState(1);

  const paginatedPosts = useMemo(() => {
    const lastIndex = current * pageSize;
    const firstIndex = lastIndex - pageSize;

    return posts.slice(firstIndex, lastIndex);
  }, [current, pageSize, posts]);

  // useEffect(() => {
  //   window.scroll({
  //     top: 500,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [current, pageSize]);

  useEffect(() => {
    if (current !== 1) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [current, pageSize]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  return (
    <div className="grid-pagination-container">
      <div className="post-grid container">
        {paginatedPosts.map((post, index) => (
          <div className="post-container" key={index}>
            <figure>
              <Link to={`/post/${post.id}`}>
                {post && post.img && (
                  <img
                    src={require(`../../../../public/uploads/${post.img}`)}
                    alt={post.alt}
                  />
                )}
              </Link>
            </figure>
            <TagRow tags={post.cat} />
            <h2>{post.title}</h2>
            <p className="author-text">
              {/* <span> */}
              {/* By: */}
              {/* <Link to={`/authors/${post.author}`}> */}
              {/* {post.author} */}
              {/* </Link> */}
              {/* </span> */}
              <span>{moment(post.date).fromNow()}</span>
            </p>
            <p className="description-text">
              {getText(post.desc.split(" ").slice(0, 20).join(" "))}...
            </p>
            {/* <Link to={post.link}>Read More...</Link> */}
          </div>
        ))}
      </div>
      <Pagination
        simple
        showSizeChanger
        onShowSizeChange={setPageSize}
        pageSize={pageSize}
        total={posts.length}
        // defaultCurrent={current}
        current={current}
        onChange={setCurrent}
      />
    </div>
  );
}
