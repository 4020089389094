export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "varsityIQ Blog",
    desc: "Go through our blog for extensive information on how to apply to universities, colleges, bursaries and much more.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Applications",
    desc: "Parents can focus on work and their children on school whilst varsityIQ takes care of the entire application process.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "We got it all covered",
    desc: "Applications to university, to colleges, for student accomodation and bursaries.",
  },
];
export const textbookAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Textbook Struggle",
    desc: "varsityIQ knows how expensive it is buy textbooks so we aim to provide you used textbooks at the cheapest prices.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Low Risk",
    desc: "varsityIQ provides a full refund period so you won't be trapped with the textbook if you don't need it after all.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Free Shipping",
    desc: "Your return order's shipping is covered by us and orders of R1000 or more receive free standard shipping.",
  },
];
export const awrapper = [
  {
    cover:
      "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover:
      "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
];
export const price = [
  {
    name: "Basic Application",
    price: "50",
    desc: "This plan is for those seeking for varsityIQ to either complete the applications for student accomodation or for bursaries.",
  },
  {
    name: "Application",
    price: "100",
    desc: "This plan is for those requiring varsityIQ to complete the application to suitable tertiary institutions.",
  },
  {
    name: "With Accomodation",
    price: "150",
    desc: "This plan is for those requiring varsityIQ to complete the application to suitable tertiary institutions and for student accomodation.",
  },
  {
    name: "With Bursary",
    price: "200",
    desc: "This plan is for those requiring varsityIQ to complete the application to suitable tertiary institutions, for student accomodation and bursaries.",
  },
];
export const faqhome = [
  {
    title:
      "Are there any restrictions on the types of students or tertiary institutions that you work with?",
    desc: "There aren't any restrictions on the types of students or tertiary institutions that we work with. If your documents are in order and the institution is in South Africa then we will complete the application on your behalf.",
  },
  {
    title:
      "What types of support and guidance do you offer to students throughout the application process?",
    desc: "Assistance with completing application forms, uploading documents, etc.  We will help with researching and selecting colleges or universities that are a good fit for the student's academic and personal goals. Access to information and resources about financial aid, scholarships, and other forms of financial support. Regular updates on application deadlines, requirements, and notifications. Ongoing support and communication throughout the entire application process, including assistance with any questions or issues that may arise.",
  },
  {
    title:
      "Are there any guarantees or refunds offered if a student is not accepted to any tertiary institution?",
    desc: "We do not guarantee or offer refunds if students are not accepted into any tertiary institution this is because your acceptance into any tertiary institution is dependent on the requirements of the institution and your results. We guarantee that your application will be completed flawlessly so that you can focus on you studies.",
  },
  {
    title:
      "Does your fees include the application fees for the tertiary institutions?",
    desc: "NO, our fees are only for the services we provide. The tertiary institution's application fees are separate and therefore, will have to paid to directly to the institution.",
  },
];
export const faqtextbook = [
  {
    title:
      "How long can I rent a textbook for? Is there a minimum or maximum rental period?",
    desc: "Textbooks can be rented out for a minimum of six months and a maximum of a year. ",
  },
  {
    title: "How do I return a rented textbook at the end of the rental period?",
    desc: "varsityIQ will send out notifications before the due date of textbooks with instructions on how to return the rented textbook.",
  },
  {
    title: "Can I write and highlight in my rented textbooks?",
    desc: "We encourage you to make notes and scribbles in your rented textbook but we do require that this is done with a pencil. This is to ensure that every student gets the opportunity get a relatively clean book and so that they can make their own notes.",
  },
  {
    title: "What happens if I damage or lose a rented textbook?",
    desc: "If you damage or lose a rented textbook, you may be responsible for paying for the replacement of the textbook. The cost of the replacement will depend on the specific textbook and its current market value. At the time of renting the textbook, it's recommended that you read and understand our policy regarding damaged or lost textbooks. If you damage or lose a rented textbook, it's important to contact us as soon as possible to inform us of the situation and to inquire about the next steps. It's essential to take care of the rented textbooks to avoid any additional charges or fees.",
  },
  {
    title: "Can I cancel a rental order after it has been placed?",
    desc: "We understand that sometimes you end up not needing the textbook therefore we offer a 30 day full refund period.",
  },
];
export const brandinghome = [
  {
    id: "01",
    heading: "Tertiary Education Applications",
    desc: "varsityIQ completes the application process to tertiary institutes on your behalf.",
  },
  {
    id: "02",
    heading: "Textbooks",
    desc: "varsityIQ offers textbook renting, buying and selling services for tertiary education students.",
  },
  {
    id: "03",
    heading: "varsityIQAi",
    desc: "Teachers and lecturers can now use our AI to create content for their students and with our advanced AI content detetctor, they can ensure that their students content is not generated by AI.",
  },
];

export const brandingtextbook = [
  {
    id: "01",
    heading: "Available Textbooks",
    desc: "Most required textbooks are available to rent with access to millions of titles.",
  },
  {
    id: "02",
    heading: "It's Cheaper",
    desc: "Varsity students can save big on textbook rentals.",
  },
  {
    id: "03",
    heading: "Sell Your Textbooks",
    desc: "Do not let your textbooks dress themselves with dust, sell it to us 😉.",
  },
];
export const formhome = {
  ares: "Ah, the sweet sound of college applications being filled out. It's like the mating call of stressed-out students everywhere. But fear not, young grasshopper, for varsityIQ is here to help you navigate the treacherous waters of higher education. Let's get you accepted faster than you can say 'FOMO'. An agent will contact you soon to get everything started!",
  subject: "applications",
  heading1: "Let's start your application!",
  para1:
    "Simply fill in the form and we will get back to you to begin your application process. You can also call or send us a text via WhatsApp.",
  heading2: "For further enquiries",
  para2:
    "Please refer to the FAQs section or go to the Contact page to send us a detailed message of your enquiries.",
};
export const formtextbook = {
  ares: "Ah, textbook rentals and sales. The age-old tradition of broke college students trying to save a buck. Well, fear not my financially savvy friend, for you've come to the right place. Let's get you the textbooks you need at a price that won't make you want to drop out and join the circus. An agent will contact you soon to get everything started!",
  subject: "textbooks",
  heading1: "What textbooks do you need?",
  para1:
    "Simply fill in the form to let us know of what textbooks you need and we will get back to you. You can also call or send us a text via WhatsApp.",
  heading2: "For further enquiries",
  para2:
    "Please refer to the FAQs section or go to the Contact page to send us a detailed message of your enquiries.",
};
