import React from "react";
import Blogging from "../components/common/blog/blogging";
import Back from "../components/common/back/back";

export default function Blog() {
  return (
    <>
      <Back title="varsityIQ Blog" />
      <Blogging />
    </>
  );
}
