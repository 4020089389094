import React from "react";
import Back from "../components/common/back/back";

export default function VarsityIQAi() {
  return (
    <>
      <Back title="launching soon" />
    </>
  );
}
