import React from "react";
import Back from "../components/common/back/back";
import {
  brandingtextbook,
  faqtextbook,
  formtextbook,
  textbookAbout,
} from "../dummydata";
import Branding from "../components/home/branding";
import AboutCard from "../components/common/about/AboutCard";
import Faq from "../components/common/about/faq";
import Form from "../components/common/form";
import Title from "../components/common/title/title";

export default function Textbooks() {
  return (
    <>
      <Back title="Rent and Sell Textbooks " />
      <Branding branding={brandingtextbook} />
      <Title
        subtitle="GET YOUR TEXTBOOK NOW"
        title="Save up to 80% on textbook rentals"
      />
      <AboutCard url="https://youtu.be/eP2sNNVr0oc" data={textbookAbout} />
      <Form formdata={formtextbook} />
      <Faq faq={faqtextbook} />
    </>
  );
}
