import React from "react";

export default function Branding({ branding }) {
  return (
    <>
      <div className="branding">
        <div className="container grid">
          {branding.map((value, index) => {
            return (
              <div className="box flex" key={index}>
                <div className="text">
                  <h1>{value.id}</h1>
                </div>
                <div className="para">
                  <h2>{value.heading}</h2>
                  <p>{value.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
