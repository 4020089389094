import React from "react";
import Back from "../components/common/back/back";

export default function TermsAndConditions() {
  return (
    <>
      <Back title="Terms and Conditions" />
      <div className="legal-container">
        <h1>TERMS AND CONDITIONS OF USE</h1>

        <p>
          Welcome to our website! By accessing or using our website, you agree
          to be bound by the following terms and conditions ("Terms"). Please
          read them carefully before using our website. If you do not agree to
          these Terms, you should not use our website.
        </p>

        <h2>ABOUT OUR WEBSITE</h2>
        <p>
          Our website provides services to help students apply for tertiary
          institutions, including colleges, universities, and other forms of
          tertiary institutes in South Africa. We also provide services related
          to student accommodation and bursaries. Additionally, we offer
          textbook renting services to tertiary students, allowing them to rent
          used textbooks for a semester or the entire academic year for cheap
          prices. Students can also sell their textbooks to us.
        </p>

        <h2>ACCOUNT REGISTRATION</h2>
        <p>
          To use certain features of our website, you may need to register for
          an account. You must provide accurate and complete information when
          registering for an account, and you must keep your account information
          up-to-date. You are responsible for maintaining the confidentiality of
          your account password, and you are solely responsible for all
          activities that occur under your account.
        </p>

        <h2>USE OF CONTENT</h2>
        <p>
          The content on our website, including but not limited to text,
          graphics, images, logos, and software, is owned by us or our licensors
          and is protected by copyright and other intellectual property laws.
          You may not copy, distribute, modify, transmit, display, perform,
          reproduce, publish, license, create derivative works from, transfer,
          or sell any content obtained from our website without our prior
          written consent.
        </p>

        <h2>DISCLAIMER OF WARRANTIES</h2>
        <p>
          Our website is provided "as is" and "as available," without any
          warranties of any kind, either express or implied, including but not
          limited to warranties of title, non-infringement, or implied
          warranties of merchantability or fitness for a particular purpose. We
          do not warrant that our website will be uninterrupted or error-free,
          that defects will be corrected, or that our website or the server that
          makes it available are free of viruses or other harmful components. We
          do not warrant or make any representations regarding the use or the
          results of the use of the materials on our website in terms of their
          correctness, accuracy, reliability, or otherwise.
        </p>

        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          Under no circumstances, including but not limited to negligence, will
          we be liable for any direct, indirect, incidental, special, or
          consequential damages that result from the use of, or the inability to
          use, our website or materials or functions on our website, even if we
          have been advised of the possibility of such damages. Applicable law
          may not allow the limitation or exclusion of liability for incidental
          or consequential damages, so the above limitation or exclusion may not
          apply to you.
        </p>

        <h2>INDEMNIFICATION</h2>
        <p>
          You agree to indemnify, defend, and hold harmless us and our officers,
          directors, employees, agents, licensors, and suppliers from and
          against all claims, liabilities, losses, expenses, damages, and costs,
          including reasonable attorneys' fees, resulting from any violation of
          these Terms by you or any activity related to your account (including
          negligent or wrongful conduct) by you or any other person accessing
          our website using your account.
        </p>

        <h2>MODIFICATIONS TO TERMS OF USE</h2>
        <p>
          We may modify these Terms at any time, and such modifications will be
          effective immediately upon posting on our website. You agree to review
          these Terms periodically to be aware of any modifications. By
          continuing to use our website after any modifications are made, you
          agree to be bound by the revised Terms.
        </p>

        <h2>TERMINATION</h2>
        <p>
          We reserve the right to terminate your access to our website at any
          time, without cause or notice.
        </p>

        <h2>GOVERNING LAW</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the Republic of South Africa, without giving effect to any
          principles of conflicts of law. You agree that any action at law or in
          equity arising out of or relating to these Terms or our website shall
          be filed only in the courts located in the Republic of South Africa,
          and you hereby consent and submit to the personal jurisdiction of such
          courts for the purposes of litigating any such action.
        </p>

        <h2>ENTIRE AGREEMENT</h2>
        <p>
          These Terms constitute the entire agreement between you and us with
          respect to the use of our website, and supersede all prior or
          contemporaneous communications and proposals, whether oral or written,
          between you and us.
        </p>

        <h2>SEVERABILITY</h2>
        <p>
          If any provision of these Terms is found to be invalid, illegal, or
          unenforceable in any respect, the validity, legality, and
          enforceability of the remaining provisions shall not be affected.
        </p>

        <h2>WAIVER</h2>
        <p>
          No waiver of any provision of these Terms shall be deemed a further or
          continuing waiver of such provision or any other provision, and our
          failure to assert any right or provision under these Terms shall not
          constitute a waiver of such right or provision.
        </p>

        <h2>ASSIGNMENT</h2>
        <p>
          These Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you, but may be assigned by us without
          restriction.
        </p>

        <h2>CONTACT US</h2>
        <p>
          If you have any questions or concerns about these Terms, please
          contact us at +27 61 897 3346 or info@varsityiq.co.za.
        </p>

        <p>Thank you for using our website!</p>
      </div>
    </>
  );
}
