import React from "react";
import AboutCard from "../components/common/about/AboutCard";
import Form from "../components/common/form";
import Branding from "../components/home/branding";
import Hero from "../components/home/hero/hero";
import { faqhome, formhome, homeAbout } from "../dummydata";
import PriceCard from "../components/common/pricing/PriceCard";
import Title from "../components/common/title/title";
import Faq from "../components/common/about/faq";
import { brandinghome } from "../dummydata";

export default function Home() {
  return (
    <>
      <Hero />
      <Branding branding={brandinghome} />
      <Title
        subtitle="YOUR UNIVERSITY JOURNEY IS OUR PRIORITY"
        title="You too can become a varsity student"
      />
      <AboutCard
        url="https://www.youtube.com/watch?v=S0EzLdXcBz4&rel=0"
        data={homeAbout}
      />
      <Form formdata={formhome} />
      <section className="price padding">
        <Title title="Pricing" />
        <div className="container grid">
          <PriceCard />
        </div>
      </section>
      <Faq faq={faqhome} />
    </>
  );
}
